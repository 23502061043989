<template>
	<div class="register">
		<div class="left-img">
			<img :src="StaticImg.leftImg" />
		</div>
		<div class="register-box">
			<img :src="StaticImg.logo" class="logo" />
			<!-- <div class="head">
				<router-link to="/login/register/distributor" class="r-router-link" active-class="r-router-link-active">供应商注册</router-link>
				<router-link to="/login/register/client" class="r-router-link" active-class="r-router-link-active">客户注册</router-link>
			</div> -->
			<div class="router-view">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
/***
 * @Author zhd
 * @Date 2021-10-22
 * @desc 注册 
 */
export default{
		setup(){
			const StaticImg = {
				logo: require('@/assets/login/logo.svg'),
				leftImg: require('@/assets/login/left-image.svg')
			}
			return {
				StaticImg
			}
		}
	}
</script>

<style lang="less" scoped>
	.register{
		width: 100%;
    	height: 100%;
		position: relative;
	}
	.left-img{
		position: absolute;
		left: 150px;
		top: 50%;
		transform: translateY(-50%);
	}
	.register-box{
		width: 1020px;
		height: 600px;
		background-color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.logo{
			position: absolute;
			left: 30px;
			top: 30px;
		}
		.router-view{
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
</style>
